<template>
  <div id="users">
    <el-breadcrumb separator="/" style="padding-left:10px;padding-bottom:10px;font-size:12px;">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>项目报表</el-breadcrumb-item>
      <el-breadcrumb-item>数据看板</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 用户列表卡片区 -->
    <el-card class="box-card" style="overflow-x: auto">
      <el-form :inline="true" ref="form" :model="queryMap" label-width="70px" size="small">
        <el-form-item label="项目">
          <el-select
              v-model="queryMap.id"
              placeholder="请选择项目"
              filterable>
            <el-option
                v-for="organ in projectOptions"
                :key="organ.id"
                :label="organ.name"
                :value="organ.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left:50px;">
          <el-button type="primary" icon="el-icon-search" @click="showList">查询</el-button>
        </el-form-item>
      </el-form>

      <div v-show="showDataBoard"
           style="padding-top: 10px;padding-bottom: 10px; margin-top: 30px; display:flex;justify-content: center;">

        <div style="background-color: white; width: 80%; padding-left: 30px; padding-right: 30px;">
          <el-divider></el-divider>

          <div style="display:flex;justify-content: center;">
            <h2 style="margin-top: 50px;">各阶段实际vs计划进度率</h2>
          </div>
          <div style="display:flex;justify-content: center; margin-top: 80px">
            <!-- 进步率图标-->
            <div ref="progress_rate" id="progress_rate" style="width:800px; height:400px;"></div>
          </div>

          <div style="display:flex;justify-content: center;">
            <h2 style="margin-top: 50px;">各部署进度延迟·纳期违反任务数</h2>
          </div>
          <div style="display:flex;justify-content: center; margin-top: 80px">
            <!-- 进度延迟/过期任务数（按部门）-->
            <div ref="progress_slippage_deploy" id="progress_slippage_deploy" style="width:600px; height:300px;"></div>
          </div>
          <div style="display:flex;justify-content: center;">
            <h2 style="margin-top: 50px;">各担当进度延迟·纳期违反任务数</h2>
          </div>
          <div style="display:flex;justify-content: center; margin-top: 80px">
            <!-- 进度延迟/过期任务数（按人员）-->
            <div ref="progress_slippage_member" id="progress_slippage_member" style="width:600px; height:300px;"></div>
          </div>

          <el-divider></el-divider>
        </div>

      </div>

    </el-card>
  </div>
</template>

<script>
export default {
  name: "dataBoard",
  data() {
    return {
      queryMap: {},
      projectOptions: [{id: 1, name: "项目1"}, {id: 2, name: "项目2"}],
      showDataBoard: false,
      progress_rate_option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },

        legend: {
          data: ['Precipitation', 'Temperature']
        },
        xAxis: [
          {
            type: 'category',
            data: ['阶段1', '阶段2', '阶段3', '阶段4'],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '实际进度率',
            min: 0,
            max: 120,
            interval: 50,
            axisLabel: {
              formatter: '{value} %'
            }
          },
          {
            type: 'value',
            name: '计划进度率',
            min: 0,
            max: 120,
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: [
          {
            name: '实际进度率',
            type: 'bar',
            data: [
              50, 30, 60, 80
            ]
          },
          {
            name: '计划进度率',
            type: 'line',
            yAxisIndex: 1,
            data: [70, 90, 88, 50]
          }
        ]
      },
      progress_slippage_deploy_option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: ['部署1', '部署2', '部署3', '部署4']
        },
        series: [
          {
            name: '进度延迟',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [320, 302, 301, 334]
          },
          {
            name: '纳期违反',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [120, 132, 101, 134]
          }
        ]
      },
      progress_slippage_member_option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: ['人员1', '人员2', '人员3', '人员4']
        },
        series: [
          {
            name: '进度延迟',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [320, 302, 301, 334]
          },
          {
            name: '纳期违反',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [120, 132, 101, 134]
          }
        ]
      },
    }
  },
  created() {
    this.getProjects()
  },
  mounted() {
    this.progress_rate = this.$echarts.init(this.$refs.progress_rate)
    this.initChart()

    this.progress_slippage_deploy = this.$echarts.init(this.$refs.progress_slippage_deploy)
    this.initChart2()

    this.progress_slippage_member = this.$echarts.init(this.$refs.progress_slippage_member)
    this.initChart3()
  },
  methods: {

    /**
     * 项目切换
     */
    showList() {
      if (this.queryMap.id !== '' && this.queryMap.id !== null && this.queryMap.id !== undefined) {
        this.showDataBoard = true
      }
    },

    /**
     * 获取项目列表
     * @returns {Promise<void>}
     */
    async getProjects() {
      const {data: res} = await this.$http.get("/system/projects");
      this.projectOptions = res.data
    },

    /**
     * 初始化报表
     */
    initChart() {
      const {
        progress_rate,
        progress_rate_option
      } = this
      progress_rate.setOption(progress_rate_option)
    },
    /**
     * 初始化报表
     */
    initChart2() {
      const {
        progress_slippage_deploy,
        progress_slippage_deploy_option
      } = this
      progress_slippage_deploy.setOption(progress_slippage_deploy_option)
    },

    /**
     * 初始化报表
     */
    initChart3() {
      const {
        progress_slippage_member,
        progress_slippage_member_option
      } = this
      progress_slippage_member.setOption(progress_slippage_member_option)
    },
  }
}
</script>

<style scoped>

</style>